import * as firebase from "firebase";
import "firebase/database";

let config = {
  apiKey: "AIzaSyBwVfLyFklG7HYiy54HZr5F0fA3JdDIo1w",
  authDomain: "el-blocke-radio.firebaseapp.com",
  databaseURL: "https://el-blocke-radio-default-rtdb.firebaseio.com",
  projectId: "el-blocke-radio",
  storageBucket: "el-blocke-radio.appspot.com",
  messagingSenderId: "835474946917",
  appId: "1:835474946917:web:d45b60fd04c8c17dfdbf8c",
  measurementId: "G-NQ1FNF9Z2Y"
};

firebase.initializeApp(config);

export default firebase.database();